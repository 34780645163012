/** @jsx jsx */
import { css, jsx, withTheme } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image";

import FolderHeader from "../../components/FolderHeader";
import Authorized from "../../components/Authorized";
import Wrapper from "../../components/Wrapper";
import Gallery from "../../components/Gallery";

import { app } from "../../config";

import bgImg from "../../images/hintergrund-ordner.png";

const AppKloesschenPage = withTheme(({ theme, location: { pathname } }) => {
  pathname = pathname.endsWith("/")
    ? pathname.substr(0, pathname.length - 1)
    : pathname;
  const character = app.routes.find((r) => r.path === pathname);

  let data = [
    {
      text: "Foto Felsenwand",
      thumbnailImage: (
        <StaticImage
          src="../../images/kloesschens-ordner/vorschaubild-felswand-01.png"
          width={345}
          layout="constrained"
          aria-hidden="true"
          placeholder="none"
          alt=""
          quality="70"
        />
      ),
      imagePath: "/lightbox/felswand01.jpg",
    },
    {
      text: "Felsenwand – Zoom",
      thumbnailImage: (
        <StaticImage
          src="../../images/kloesschens-ordner/vorschaubild-felswand-03.png"
          width={345}
          layout="constrained"
          aria-hidden="true"
          placeholder="none"
          alt=""
          quality="70"
        />
      ),
      imagePath: "/lightbox/felswand03.jpg",
    },
    {
      text: "Zerrissene Visitenkarte",
      thumbnailImage: (
        <StaticImage
          src="../../images/kloesschens-ordner/vorschaubild-visitenkarte.png"
          width={345}
          layout="constrained"
          aria-hidden="true"
          placeholder="none"
          alt=""
          quality="70"
        />
      ),
      imagePath: "/lightbox/visitenkarte.jpg",
    },
  ];

  return (
    <main
      css={css`
        overflow: hidden;
      `}
    >
      <FolderHeader text={character.name} />
      <div
        css={css`
          position: relative;
          background-color: ${theme.color["light-blue"]};
          padding-top: ${76 + 80}px;
          min-height: calc(100vh - 160px);
          ${theme.funcs.mediaquery(
            "xl",
            `
          padding-top: ${95 + 65}px;
      `
          )}
        `}
      >
        <Authorized scope={pathname}>
          <Wrapper
            css={css`
              position: relative;
              z-index: 1;
              color: ${theme.color.spot1};
              padding-bottom: 60px;
            `}
          >
            <Gallery data={data} />
          </Wrapper>
        </Authorized>
        <img
          src={bgImg}
          aria-hidden="true"
          css={css`
            position: absolute;
            right: 0;
            bottom: 0;
          `}
          alt=""
        />
      </div>
    </main>
  );
});

export default AppKloesschenPage;
